import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import Swal from 'sweetalert2';
import { PdfService } from 'src/app/services/pdf.service';
import { ExcelService } from 'src/app/services/excel.service';
import { UserService } from 'src/app/services/user.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.css']
})
export class ModalComponent implements OnInit {

  @Input() data: any;
  @Input() type: any;

  alcanceTotal: number;
  valorTotal: number;

  @ViewChild('scrollOne', { static: false }) scrollOne: ElementRef;
  @ViewChild('scrollThree', { static: false }) scrollThree: ElementRef;

  typesNotes: any[] = [
    { id: 1, name: "Nota" },
    { id: 2, name: "Entrevista" },
    { id: 3, name: "Reseña" },
    { id: 4, name: "Opinión" },
    { id: 5, name: "Mención" },
    { id: 6, name: "Nota con UTM" },
  ];

  tiers: any[] = [
    { id: 1, name: "Tier 1" },
    { id: 2, name: "Tier 2" },
    { id: 3, name: "Tier 3" }
  ];

  dataTitleOne: string[] = [];
  dataTableOne: any[] = [];
  dataSliceOne: any[] = [];
  dataTableOneTotals: any[] = [];

  name: string = '';
  dataTableTwo: any[] = [];
  dataSliceTwo: any[] = [];

  title = ''
  typesReport = ['0x1', '1x1', '2x1', '3x1', '5x1', '6x1', '0x2', '1x2', '2x2', '3x2', '4x2', '6x2', '0x3', '1x3', '2x3', '3x3', '4x3', '5x3']

  id:any 
  constructor(
    private userService: UserService,
    private pdfService: PdfService,
    private excelService: ExcelService
  ) { }

  ngOnInit(): void {

    
    this.alcanceTotal = this.data.alcanceTotal
    
    this.valorTotal = Number(this.data.valorizadoTotal.toFixed(2))
    /*this.userService.getInfoBusiness().subscribe(res => {
      let data = res['empresa']
      this.id = data.id
    })*/
    console.log(this.data)
    this.modalView()
  }

  ngOnChanges() {
    this.modalView()
  }


  modalView() {
    this.dataTableTwo = []
    this.dataTitleOne = []
    this.dataTableOne = []

    const ylabels = [
      { title: 'Campañas', name: 'Campaign', id: 'idCampaign', arr: this.data.params.campaigns },
      { title: 'Voceros', name: 'Vocero', id: 'idVocero', arr: this.data.params.voceros },
      { title: 'Plan de Medios', name: 'PlanMedio', id: 'idPlanMedio', arr: this.data.params.planmedios },
      { title: 'Plataformas', name: 'Plataforma', id: 'idPlataforma', arr: this.data.params.plataformas },
      { title: 'Tipo de Notas', name: 'note', id: 'tipoNota', arr: this.data.params.tipoNotas },
      { title: 'Tiers', name: 'tierName', id: 'tipoTier', arr: this.data.params.tipoTiers.filter(t => !!t) },
    ]
    const xlabels = [
      { arrTitle: this.data.params.platforms.map(pl => pl.descripcion), arr: this.data.params.plataformas, id: 'idPlataforma' },
      { arrTitle: this.typesNotes.filter(n => this.data.params.tipoNotas.includes(n.id)).map(n => n.name), arr: this.data.params.tipoNotas, id: 'tipoNota' },
      { arrTitle: this.tiers.filter(n => this.data.params.tipoTiers.includes(n.id)).map(n => n.name), arr: this.data.params.tipoTiers.filter(t => !!t), id: 'tipoTier' }
    ]

    let x = xlabels[this.data.xlabel - 1]
    let y = ylabels[this.data.ylabel - 1]

    this.title = y.title.toUpperCase()
    if (this.type == 1) {
      this.name = y.name
      this.dataTitleOne = [y.title].concat(x.arrTitle).concat(['Total'])
      y.arr.forEach(cm => {
        let arrResult = this.data.DPRPs.filter(dt => dt[y.id] == cm)
          .sort((a, b) => a[x.id] - b[x.id])
          .map((dp, ind) => {
            dp['shwoFirst'] = ind == 0
            return dp
          })
        if (arrResult.length) {
          this.dataTableOne.push({
            name: arrResult[0][y.name],
            quantity: x.arr.map(pl => arrResult.filter(cp => cp[x.id] == pl).length),
            total: arrResult.length
          })
          this.dataTableTwo = this.dataTableTwo.concat(arrResult)
        }
      })

      this.dataTableOneTotals = x.arr.map(pl => this.data.DPRPs.filter(cp => cp[x.id] == pl).length)
        .concat([this.data.DPRPs.length])
    }


    let z = this.data.xlabel ? ylabels[this.data.xlabel + 2] : ylabels[this.data.ylabel - 1]
    if (this.type == 2) {
      this.name = z.name
      this.dataTitleOne = [z.title.toUpperCase(), 'PUBLICACIONES']
      z.arr.forEach(pl => {
        let arrResult = this.data.DPRPs.filter(dt => dt[z.id] == pl).map((dp, ind) => {
          dp['shwoFirst'] = ind == 0
          return dp
        })

        if (arrResult.length) {
          this.dataTableTwo = this.dataTableTwo.concat(arrResult)

          this.dataTableOne.push({
            name: arrResult[0][z.name],
            quantity: [],
            total: arrResult.length
          })
        }
      })
      this.dataTableOneTotals = [this.data.DPRPs.length]
    }

    this.dataSliceOne = this.dataTableOne.slice(0, 10);
    this.dataSliceTwo = this.dataTableTwo.slice(0, 10);
  }


  onModalScrollDownOne() {
    if (this.dataSliceOne.length < this.dataTableOne.length) {
      const lengthArray = this.dataSliceOne.length;
      const lengthArrayTwo = this.dataTableOne.length;
      for (let i = lengthArray; i < lengthArray + 10 && i < lengthArrayTwo; i++) {
        this.dataSliceOne.push(this.dataTableOne[i])
      }
    }
  }

  onModalScrollDownTwo() {
    if (this.dataSliceTwo.length < this.dataTableTwo.length) {
      const lengthArray = this.dataSliceTwo.length;
      const lengthArrayTwo = this.dataTableTwo.length;
      for (let i = lengthArray; i < lengthArray + 10 && i < lengthArrayTwo; i++) {
        this.dataSliceTwo.push(this.dataTableTwo[i])
      }
    }
  }

  closeModal() {
    this.scrollOne.nativeElement.scrollTop = 0;
    this.scrollThree.nativeElement.scrollTop = 0;
    this.dataSliceOne = this.dataTableOne.slice(0, 10);
    this.dataSliceTwo = this.dataTableTwo.slice(0, 10);
  }

  excel() {
    let type: number;
    const string = `${this.data.type == 1 ? this.data.ylabel : 0}x${this.data.xlabel}`;
    type = this.typesReport.indexOf(string) + 1;
    this.excelService.exportToExcel(this.data, type);
  }

  pdf() {
    const string = `${this.data.type == 1 ? this.data.ylabel : 0}x${this.data.xlabel}`
    console.log(string)
    if (this.data.DPRPs === 0) {
      return;
    }
    /*
        if (this.data.params.campaigns.length > 0 && !this.campaigns && !this.platforms) {
          Swal.fire({
            title: 'Generar reporte',
            text: 'Debe escoger un tipo de reporte por campaña o plataforma',
            imageUrl: 'assets/img/error.png',
            customClass: {
              title: 'my-swal-title-class',
              content: 'my-swal-content-class',
              confirmButton: 'my-swal-confirm-button-class',
            }
          });
          return;
        }*/

    const data = {
      ...this.data.params,
      tipoReporte: this.typesReport.indexOf(string) + 1
    }

    this.pdfService.pdf(data);
  }

}
